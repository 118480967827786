import React, { useEffect, useState } from 'react';
import styles from './index.module.scss'
import classNames from "classnames";
import MainSvgTab from "../../svg/mainSvgTab";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';


import LogoComponent from '../../data/LogoComponent'

const widthWindow = document.documentElement.clientWidth <= 768

const BlockLeft = ({ activeDoorAnimation, closeDoorBlock, arrayDataTab, currentTab, params, data, campid, publicKey }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const url = "https://icreate-campaign.com/Admin/WS/InsertLeadCRM";
    const [submitForm, setSubmitForm] = useState(false)


    useEffect(() => {
        setSubmitForm(false)
    }, [currentTab])

    const onSubmit = data => {

        axios.post('https://hook.eu1.make.com/snh8mbc8p6p5oldydd0yy3labsxup4vm', data);

        // window.fbq('track', 'Lead');

        setSubmitForm(true)
    }


    const getCookieValue = (name) => {
        return Cookies.get(name);
    };

    const setCookieValue = (name,value) => {
        Cookies.set(name,value, {expires: 90})
    }


    useEffect(() => {
        if (params.gclid) {
            setCookieValue('gclid', params.gclid)
        }
        if (params.utm_campaign) {
            setCookieValue('utm_campaign', params.utm_campaign)
        }
        if (params.utm_medium) {
            setCookieValue('utm_medium', params.utm_medium)
        }
        if (params.utm_source) {
            setCookieValue('utm_source', params.utm_source)
        }
        // console.log(getCookieValue('utm_source'))
    })




    return (
        <>
            {arrayDataTab.map(({ id, color, titleMain, customSend,  textBorder1, textBorder2, textBorderMob1, textBorderMob2, textBorderMob3, textBorderMob4, titleText, mainText, titleUnderText,
                textCount, count, href }) => {

                return (

                    id === currentTab &&
                    <div key={id + 'arrayDataTab'}
                        className={classNames(styles.blockDoorOne, {
                            [styles.active]: activeDoorAnimation
                        })}>
                        <Link onClick={() => { closeDoorBlock() }} to={{ pathname: "/", search: window.location.search }} className={styles.close} style={{ background: color }}>
                            <p>חזור<br />
                                לדף הבית</p>
                        </Link>
                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.parent}>
                                <LogoComponent fillColor={color} />
                            </div>
                            <p className={styles.ContentTitle}>{titleMain}</p>

                            <div style={{
                                borderBottom: `1px solid ${color}`,
                                borderTop: `1px solid ${color}`
                            }} className={styles.CountBlock}>
                                <p className={styles.rtl} style={{ color: color }}>
                                    {textBorder1}
                                </p>
                            </div>

                        </div>
                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.blockDes}>
                                <p className={styles.blockDesTitle} dangerouslySetInnerHTML={{ __html: titleText }}>
                                </p>
                                <p className={styles.blockDesText} dangerouslySetInnerHTML={{ __html: mainText }}>
                                </p>
                            </div></div>

                        <div className={styles.blockDoorOneContent}>
                            <div className={styles.blockDes}>

                                <p className={styles.blockDesSubText}>
                                    {titleUnderText}
                                </p>

                                <p className={classNames(styles.blockDesText, styles.adaptive)}>
                                    {textCount} ₪{count}
                                </p>
                                

                            </div>
                        </div>
                        {!submitForm ?

                            <form onSubmit={handleSubmit(onSubmit)} className={styles.blockForm}>
                                <p className={styles.blockFormTitle}>
                                    זה הזמן להתקדם לדירה בהטבה! מלאו פרטים ונחזור אליכם בהקדם לתיאום פגישה: 
                                </p>

                                <div className={styles.wrapperInput}>
                                    <div className={styles.formInput}>
                                        {errors.full_name && <span className={styles.error}>⚠ אנא הזן שם מלא</span>}
                                        <input onKeyPress={(event) => {
                                            if (!/[a-zA-Za-zA-Z\u0590-\u05FF\u200f\u200e ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} className={classNames(styles.blockFormInput, styles.fullName)} {...register("full_name", { required: true })} type="text" />
                                        <span>שם מלא:</span>
                                    </div>
                                    <div className={styles.formInput}>
                                        {errors.email && <span className={styles.error}>⚠ אנא הזן כתובת מייל חוקית</span>}
                                        <input className={classNames(styles.blockFormInput, styles.email)} {...register("email", { required: true })} type="text" />
                                        <span>מייל: </span>
                                    </div>
                                        
                                    <input {...register("select")} type="hidden" value={customSend ?? titleMain} />
                                    <input {...register("media_source")} type="hidden" value={params?.utm_source ? params?.utm_source : getCookieValue('utm_source')} />
                                    <input {...register("media_channel")} type="hidden" value={params?.utm_source ? params?.utm_source : getCookieValue('utm_source')} />
                                    <input {...register("utm_source")} type="hidden" value={params?.utm_source ? params?.utm_source : getCookieValue('utm_source')} />
                                    <input {...register("utm_medium")} type="hidden" value={params?.utm_medium ? params?.utm_medium : getCookieValue('utm_medium')} />
                                    <input {...register("utm_campaign")} type="hidden" value={params?.utm_campaign ? params?.utm_campaign : getCookieValue('utm_campaign')} />
                                    <input {...register("utm_term")} type="hidden" value={params?.utm_term ? params?.utm_term : getCookieValue('utm_term')} />
                                    <input {...register("utm_content")} type="hidden" value={params?.utm_content ? params?.utm_content : getCookieValue('utm_content')} />
                                    <input {...register("gclid")} type="hidden" value={params?.gclid ? params?.gclid : getCookieValue('gclid')} />

                                </div>
                                <div className={styles.wrapperButton}>
                                    <div className={styles.formInputTwo}>
                                        {errors.phone && <span className={styles.error}>⚠ אנא הזן מספר פלאפון תקין</span>}
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} className={classNames(styles.blockFormInput, styles.optOut)} {...register("phone", { required: true })} type="text" />
                                        <span>טלפון:</span>
                                    </div>
                                    <div className={styles.wrapperButtonFix}>
                                        <div>
                                            <input id="checkbox" {...register("email_opt_out", { required: false })} type="checkbox" />
                                            <label htmlFor="checkbox" className={styles.checkboxTitle}>אני מעוניין/ת לקבל מידע שיווקי והצעות (ללא התחייבות) במייל / וואטסאפ / SMS מחברת רוטשטיין

                                            </label>
                                        </div>
                                        <button className={styles.blockFormButton} style={{ background: color }}>שלח</button>
                                    </div>
                                </div>

                            </form>
                            :
                            <div className={styles.succsessSubmit}>
                                <p>
                                    <span>תודה,</span><br /><span>פרטיך התקבלו</span><br /><span>בהצלחה!</span>
                                </p>
                            </div>}
                        <div className={styles.iconFirms}>
                            <MainSvgTab />

                            <img class={styles.brandIcon} src={href} />
                        </div>
                        <p className={classNames(styles.formBottomTextDesk)}>
                            *מילוי פרטיך ולחיצה על כפתור ״חזרו אליי״ מהווה הסכמה מנציג מכירות מטעם חברת רוטשטיין ליצור איתך קשר באמצעות הטלפון/ הודעת SMS או WhatsApp  *ההטבות משתנות מפרויקט לפרויקט *בכפוף* מוגבל למספר דירות בכל פרויקט *לחותמים על הסכם רכישה עד ה- 31.12.2024 בלבד *ההדמיות להמחשה בלבד.  *החברה רשאית להפסיק את המבצע בכל עת *ט.ל.ח.


                        </p>
                        <p className={styles.formBottomText}>
                            *מילוי פרטיך ולחיצה על כפתור ״חזרו אליי״ מהווה הסכמה מנציג מכירות מטעם חברת רוטשטיין ליצור איתך קשר באמצעות הטלפון/ הודעת SMS או WhatsApp  *ההטבות משתנות מפרויקט לפרויקט *בכפוף* מוגבל למספר דירות בכל פרויקט *לחותמים על הסכם רכישה עד ה- 31.12.2024 בלבד *ההדמיות להמחשה בלבד.  *החברה רשאית להפסיק את המבצע בכל עת *ט.ל.ח.


                        </p>
                    </div>

                )
            })}
        </>

    );
};

export default BlockLeft;